import MainLayout from '@/layouts/main'
import styled from 'styled-components'
import React from 'react'
import useAuth from '@/hooks/useAuth'
import LoadingScreen from '@/components/LoadingScreen'
import { Stack, Button, Typography } from '@mui/material'
import { GlobalSetting, settingService } from '@/services/page'
import { APPS } from '@/appConfig'
import pathService from '@/services/path'
import { PATH_APP_SERVICES } from '@/routes/paths'
import { GetStaticPaths, GetStaticProps } from 'next'
import { ParsedUrlQuery } from 'querystring'
import Page from '@/components/Page'

const Layout = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const StyledContainer = styled.div``

interface PathProps extends ParsedUrlQuery {
  appId: string
}

interface LoginPageProps {
  globalSetting?: GlobalSetting
}

LoginPage.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <MainLayout>
      <Layout>
        <StyledContainer>{page}</StyledContainer>
      </Layout>
    </MainLayout>
  )
}

export default function LoginPage({ globalSetting }: LoginPageProps) {
  const { user, error, isLoading, logout, login } = useAuth()
  const companyColor = globalSetting?.companyColor ?? undefined
  if (isLoading) return <LoadingScreen companyColor={companyColor} />
  if (error) return <div>{error.message}</div>
  if (user) {
    return (
      <Stack spacing={3}>
        <Typography>
          Welcome {user.name}!
        </Typography>
        <Button variant="text" onClick={logout}>
          Logout
        </Button>
      </Stack>
    )
  }

  return (
    <Page title={'AI Writer'}>
      <Button variant="text" onClick={() => login({ returnTo: PATH_APP_SERVICES.root })}>
        Login
      </Button>
    </Page>
  )
}

export const getStaticPaths: GetStaticPaths<PathProps> = async () => {
  const { getDefaultPaths } = pathService()
  const paths = APPS.flatMap((app) => getDefaultPaths(app.id))

  return {
    paths,
    fallback: true,
  }
}

export const getStaticProps: GetStaticProps<LoginPageProps, PathProps> = async ({
  params,
  locale,
}) => {
  if (!params) throw new Error('No path parameters found')

  const { appId } = params

  const { getGlobalSetting } = settingService(appId)
  const [globalSetting] = await Promise.all([getGlobalSetting({ locale })])

  return {
    props: {
      globalSetting,
    },
    revalidate: 3600,
  }
}
